.wrapper {
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  min-height: 100vh;
}

.container {
  width: min(1000px, 90%);
  font-size: 1.6rem;
  text-align: center;
  margin: 4rem 0;
  z-index: 1;
}

.error_code {
  font-family: "Yeseva One", cursive;
  line-height: 1;
  margin: 0 0 4rem;
  font-size: 10em;
  color: #EF476F;
}

.error_message {
  font-size: 2em;
  line-height: 1.3;
  margin: 4rem 0 3rem;
}

.error_action {
  display: inline-block;
  margin: 0 0.5em;
  line-height: 1.7;
}

.error_action .link,
.error_action .link:visited {
  display: inline-block;
  margin: 0 0.5em;
  text-decoration: underline;
}

.wave {
  position: absolute;
  bottom: 0;
  z-index: 0;
}
