.cursor {
  position: fixed;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 50%;
  border: 1px solid #fefefe;
  opacity: 1;
  z-index: 100;
  pointer-events: none;
  mix-blend-mode: difference;
  transition: all 200ms ease-out, opacity 50ms ease;
}
@media (hover: none) {
  .cursor {
    display: none;
  }
}

.clicked {
  transform: translate(-50%, -50%) scale(0.8);
}

.hidden {
  opacity: 0;
}

.hover {
  transform: translate(-50%, -50%) scale(1.5);
  background-color: #fefefe;
}

.highlight {
  transform: translate(-50%, -50%) scale(2.5);
  background-color: #fefefe;
}
