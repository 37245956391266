html {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 1.45;
  color: #333;
  background-color: #F9F9F9;
}

@media (max-width: 800px) {
  html {
    font-size: 9px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 8px;
  }
}
body {
  min-height: 100vh;
  min-width: 300px;
  background-color: #F9F9F9;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus-visible {
  outline: none;
}

*:focus {
  outline: none;
}

body a:focus-visible,
body button:focus-visible {
  outline-color: #EF476F;
  outline-offset: -0.2rem;
  outline-style: dashed;
  outline-width: 1px;
}

*:link,
*:visited {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0.4em 0 0.5em;
}

ul {
  list-style: none;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.3rem;
}

.sr_only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.section {
  display: flex;
  flex-direction: column;
}

.container {
  width: min(1200px, 100%);
  margin: 0 auto;
}

.container_text {
  padding: 0 2rem;
}
@media (min-width: 500px) {
  .container_text {
    padding: 0 5rem;
  }
}

.text_lg {
  font-size: 2.4rem;
  line-height: 1.4;
  font-weight: 600;
  margin: 0 0 2.2em;
  color: #404040;
  transform: translateY(1em);
  opacity: 0;
  transition: 0.8s ease 0.3s;
}
.text_lg.text_lg__isVisible {
  transform: translateY(0);
  opacity: 1;
}

.page_wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.main {
  flex-grow: 1;
}

.hidden {
  visibility: hidden;
}

.flex {
  display: flex;
}
